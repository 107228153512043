$theme-colors: (
  "primary": #b18731,
);

.logo{
  max-height: 35px;
}

.logo-md{
  max-height: 75px;
}

.spacer {
  flex: 1 1 auto;
}
.desktop{
  display: flex;
}

.mobile{
  display: none;
}

button{
  white-space: pre-wrap !important;
}

@media (max-width: 768px) {
  .desktop{
      display: none;
  }
  
  .mobile{
      display: flex;
  }
}
.mat-checkbox-layout{
  white-space: inherit !important;
}

.loader{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #b186312a;
  z-index: 9999;
}

.initials{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  background-color: #eeeeee;
  color: var(--primary);
  border-radius: 50px;
  font-size: 0.7rem;
  overflow: hidden;
  img{
    width: 100%;
    max-width: 2rem;
  }
}

.profile-pic-initials{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  min-width: 4rem;
  background-color: #f0f0f0;
  color: white;
  border-radius: 50px;
  font-size: 0.7rem;
  overflow: hidden;
  margin: 0.5rem;
  &.sm{
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    border-radius: 100px;
  }
  &.lg{
    height: 8rem;
    width: 8rem;
    min-width: 8rem;
    border-radius: 100px;
  }
  &.xl{
    height: 12rem;
    width: 12rem;
    min-width: 12rem;
    min-height: 12rem;
    border-radius: 100px;
  }
}

.link{
  cursor: pointer !important;
}

.flex-1{
  flex: 1;
}

.border-primary{
  border: 1px solid var(--primary);
  border-radius: 10px;
}

.fab-top-left{
  position: fixed !important;
  top: 75px;
  left: 15px;
  z-index: 999;
}

.fab-top-right{
  position: fixed !important;
  top: 75px;
  right: 15px;
  z-index: 999;
}
.top-right{
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 999;
}
.fab-bottom-right{
  bottom: 15px;
  right: 15px;
  position: fixed !important;
  z-index: 9999;
}

.fab-bottom-left{
  bottom: 15px;
  left: 15px;
  position: fixed !important;
  z-index: 9999;
}

.fab-chat{
  // bottom: 50px !important;
}

.relative{
  position: relative;
}

.mat-tab-label{
  padding: 30px 24px !important;
}
.mat-tab-body{
  min-height: 90vh !important;
}

.mat-drawer-inner-container{
  min-width: 300px;
}

.mat-paginator-container {
  justify-content: center !important;
}
$theme-colors: (
  "primary": #b18731,
  "secondary": #1C8D9C,
  "accent": #cc82e7
);


.btn-group .btn:not(:first-child){
    margin-left: 0px !important;
}

@media(max-width: 520px){
  .mat-drawer-inner-container{
    width: 100vw !important;
  }
}
.mat-vertical-content-container{
  margin-left: 15px !important;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header{
  padding-left: 0px !important;
}
.cal-week-view .cal-day-headers{
position: sticky;
top: 0px;
z-index: 999;
background: #ffffff;
}

.border-radius-5{
  border-radius: 5px;
}
.border-radius-8{
  border-radius: 8px;
}


  .eventColor1 {
   background: #a4bdfc;
   border-color: #7080aa;
   color: #1d1d1d;
   border-radius: 6px;
  };
  .eventColor2 {
   background: #7ae7bf;
   border-color: #4d9279;
   color: #1d1d1d;
   border-radius: 6px;
  };
  .eventColor3 {
   background: #dbadff;
   border-color: #9877b1;
   color: #1d1d1d;
   border-radius: 6px;
  };
  .eventColor4 {
   background: #ff887c;
   border-color: #a85952;
   color: #1d1d1d;
   border-radius: 6px;
  };
  .eventColor5 {
   background: #fbd75b;
   border-color: #9d8739;
   color: #1d1d1d;
   border-radius: 6px;
  };
  .eventColor6 {
   background: #ffb878;
   border-color: #976b45;
   color: #1d1d1d;
   border-radius: 6px;
  };
  .eventColor7 {
   background: #46d6db !important;
   border-color: #339b9e !important;
   color: #1d1d1d !important;
   border-radius: 6px;
  };
  .eventColor8 {
   background: #e1e1e1;
   border-color: #a1a1a1;
   color: #1d1d1d;
   border-radius: 6px;
  };
  .eventColor9 {
   background: #5484ed;
   border-color: #3d60ab;
   color: #1d1d1d;
   border-radius: 6px;
  };
  .eventColor10 {
   background: #51b749;
   border-color: #357830;
   color: #1d1d1d;
   border-radius: 6px;
  };
  .eventColor11 {
   background: #dc2127;
   border-color: #95161b;
   color: #1d1d1d;
   border-radius: 6px;
  }
.max-h-90{
  max-height: 88vh;
}

.max-h-500{
  max-height: 500px;
}

.scroll{
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.bg-50perc-white{
  background: rgba(255,255,255,0.8) !important;
}

.bg-50perc-black{
  background: rgba(0,0,0,0.8) !important;
}


.bg-grey{
  background: rgba(241, 241, 241, 1) !important;
}

.bg-black{
  background: #020202 !important;
}

.h-100{
  height: 100vh;
}

.h-80{
  height: 80vh !important;
}

.input-sm{
  max-width: 60px !important;
}

.input-md{
  max-width: 100px !important;
}

.scrollable{
  height: 100%;
  overflow: scroll !important;
}

.mat-toolbar-single-row{
  position: sticky;
  top: 0px;
  z-index: 999;
}
body{
  height: 100vh;
}

.mat-card{
  border-radius: 16px !important;
  padding: 24px !important;
}

.text-sm{
  font-size: small !important;
  line-height: 20px;
}

.nowrap{
  white-space: nowrap !important;
}

.no-border{
  border: none !important
}

.absolute-bottom{
  position: absolute;
  bottom: 0;
}

.mat-dialog-container{
    padding: 0px !important;
    border-radius: 10px !important;
}
.sticky-top-16{
  position: sticky;
  top: 16px;
  z-index: 999;
}
.sticky-top{
  position: sticky;
  top: 0px;
  z-index: 999;
}

@import "./../node_modules/bootstrap/scss/bootstrap";
@import '~lightgallery/scss/lightgallery';

